.filter-gray {
  filter: grayscale(1);
}

.bg-primary {
  @apply text-indigo-50;
}

.hover\:bg-primary:hover {
  @apply bg-indigo-700;
}

.hover\:text-light:hover {
  @apply text-indigo-100;
}

.btn {
  @apply bg-origin-border;
  @apply px-4 py-2;
  @apply border border-transparent;
  @apply text-base font-medium;
  @apply rounded-md shadow-sm;
}

.btn-primary {
  @apply btn;
  @apply bg-gradient-to-r from-purple-700 to-primary hover:from-purple-800 hover:to-indigo-800;
  @apply text-white;
}

.btn-light {
  @apply btn;
}